import { throwError } from 'rxjs';
import { ajax, AjaxError, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

export type RequestInterceptor = (options: Partial<AjaxRequest>) => Partial<AjaxRequest>;
export type ResponseInterceptor = (
    options: Partial<AjaxResponse> | Partial<AjaxError>,
) => Partial<AjaxResponse> | Partial<AjaxError>;

export interface Interceptors {
    request: RequestInterceptor[];
    response: ResponseInterceptor[];
}

export default class HttpClient {
    private interceptors: Interceptors = {
        request: [],
        response: [],
    };

    public request(options: AjaxRequest) {
        return ajax(this.interceptRequest(options)).pipe(
            map((res) => this.interceptResponse(res)),
            catchError((err: AjaxError) => {
                return throwError(this.interceptResponse(err));
            }),
        );
    }

    private interceptRequest(request: Partial<AjaxRequest>): Partial<AjaxRequest> {
        for (const interceptor of this.interceptors.request) {
            // tslint:disable-next-line: no-parameter-reassignment
            request = interceptor(request);
        }

        return request;
    }

    private interceptResponse(response: Partial<AjaxResponse> | Partial<AjaxError>): any {
        for (const interceptor of this.interceptors.response) {
            // tslint:disable-next-line: no-parameter-reassignment
            response = interceptor(response);
        }

        return response.response;
    }
}
