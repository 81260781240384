import { IConversationBlock, IPostbackButton, IPostbackSuggestion, IUserMessage } from '@/models/bot-models';
import { IConversationParameters } from '@/models/chat-parameters';
import SERVICE_IDENTIFIERS from '@/models/service-identifiers';
import ChatParametersProvider from '@/services/chat-parameters';
import MessageSocketService from '@/services/message-socket';
import store from '@/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { inject, injectable } from 'vue-typescript-inject';

@injectable()
export default class ChatService {
    public isInit = new BehaviorSubject<boolean>(false);

    constructor(
        @inject(SERVICE_IDENTIFIERS.SOCKET) private readonly messageSocketService: MessageSocketService,
        @inject() private readonly chatParametersProvider: ChatParametersProvider,
    ) {}

    public sendUserMessage(message: string) {
        this.sendServerMessage({
            content_type: 'text',
            content: {
                value: message,
            },
        });
    }

    public storePushNotificationSubscription(pushSubscription: PushSubscription): Observable<any> {
        return this.messageSocketService.storePushNotificationSubscription(pushSubscription);
    }

    public sendPostbackSuggestion(postback: IPostbackSuggestion) {
        delete postback.suggestion_type;
        this.sendServerMessage({
            content_type: 'postback',
            content: {
                ...postback,
            },
        });
    }

    public sendLocationSuggestion(position: Position) {
        this.sendServerMessage({
            content_type: 'location',
            content: {
                coordinates: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                },
            },
        });
    }

    public sendPostbackButton(button: IPostbackButton) {
        this.sendServerMessage({
            content_type: 'postback',
            content: {
                ...button.payload,
                text: button.title,
            },
        });
    }

    public storeClientIdIfNotExists(clientId: string | null) {
        this.messageSocketService.storeClientIdIfNotExists(clientId);
    }

    public init() {
        if (this.isInit.getValue()) {
            return;
        }
        this.isInit.next(true);
        store.dispatch('showStartButton');
        store.dispatch('hideGreetingMessage');
        const chatParams: IConversationParameters = this.chatParametersProvider.getConversationParameters();
        this.storeClientIdIfNotExists(chatParams.clientId);
        this.messageSocketService.get(chatParams.referral).subscribe((block: IConversationBlock) => {
            store.dispatch('renderConversationBlock', block);
            store.dispatch('popChatIfNotDisplayed', block);
        });
    }

    private sendServerMessage(message: IUserMessage) {
        store.dispatch('showPendingUserMessage', message);
        this.messageSocketService.send(message);
    }
}
