import { AppConfig } from '@/app.config';
import MessageMixin from '@/mixins/message-mixin';
import { ICommandBotMessageContent } from '@/models/bot-models';
import ChatService from '@/services/chat';
import store from '@/store';
import { Component, Prop } from 'vue-property-decorator';
import { inject } from 'vue-typescript-inject';

@Component
export default class BotCommandMessage extends MessageMixin {
    @Prop({ default: () => ({}) }) public content!: ICommandBotMessageContent;
    @inject() private readonly chatService!: ChatService;
    @inject() private readonly appConfig!: AppConfig;

    public mounted(): void {
        try {
            if (this.content.command_name === 'ask_for_notifications') {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        navigator.serviceWorker
                            .register(`/${process.env.VUE_APP_ASSETS_DIR ?? ''}js/service-worker.js`)
                            .then((registration) => {
                                let serviceWorker;
                                if (registration.installing) {
                                    serviceWorker = registration.installing;
                                } else if (registration.waiting) {
                                    serviceWorker = registration.waiting;
                                } else if (registration.active) {
                                    serviceWorker = registration.active;
                                }

                                const subscribeOptions = {
                                    userVisibleOnly: true,
                                    applicationServerKey: this.urlBase64ToUint8Array(
                                        this.appConfig.pushNotificationPublicKey,
                                    ),
                                };
                                if (serviceWorker) {
                                    serviceWorker.addEventListener('statechange', (e: any) => {
                                        if (e.target.state === 'activated') {
                                            registration.pushManager
                                                .subscribe(subscribeOptions)
                                                .then((pushSubscription) => {
                                                    this.chatService
                                                        .storePushNotificationSubscription(pushSubscription)
                                                        .subscribe({
                                                            error: this.displayErrorMessage,
                                                        });
                                                }, this.displayErrorMessage);
                                        }
                                    });
                                }
                            }, this.displayErrorMessage);
                    }
                });
            }
        } catch (e) {
            if (e instanceof DOMException) {
                this.displayErrorMessage();
            }
        }
    }

    private displayErrorMessage() {
        store.dispatch('showChatErrorMessage', {
            content: 'Impossible de vous abonner aux notifications du bot ...',
        });
    }

    private urlBase64ToUint8Array(base64String: string): Uint8Array {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        return new Uint8Array(rawData.length).map((_, idx) => rawData.charCodeAt(idx));
    }
}
