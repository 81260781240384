import { AppConfig } from '@/app.config';
import { IConversationBlock, IReferral, IUserMessage } from '@/models/bot-models';
import MessageSocketService from '@/services/message-socket';
import store from '@/store';
import HttpClient from '@/utils/http-client';
import { EMPTY, from, interval, Observable, of, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { inject, injectable } from 'vue-typescript-inject';

interface Credentials {
    client_id: string;
    token: string | null;
}

@injectable()
export default class ShortPollingService implements MessageSocketService {
    private get tokenKey(): string {
        return `${this.storagePrefix}-botToken`;
    }

    private get clientIdKey(): string {
        return `${this.storagePrefix}-botConversationId`;
    }

    private sendingNewMessages = false;

    private credentials!: Credentials | null;
    private http: HttpClient;
    private lastTimestamp!: number | null;

    private readonly botName: string;
    private readonly botEnvironment: string;
    private readonly chatEndpoint: string;
    private readonly pollingIntervalMs: number;
    private readonly storagePrefix: string;
    private readonly referralEntityName: string;

    constructor(@inject() appConfig: AppConfig) {
        this.botName = appConfig.botName;
        this.botEnvironment = appConfig.botEnvironment;
        this.chatEndpoint = appConfig.chatEndpoint;
        this.pollingIntervalMs = appConfig.pollingIntervalMs;
        this.storagePrefix = appConfig.storagePrefix;
        this.referralEntityName = appConfig.referralEntityName;
        // get credentials from local storage if they exist
        const credentials: Credentials | null = this.getStoredCredentials();
        if (credentials) {
            this.credentials = credentials;
        }
        this.http = new HttpClient();
    }

    public setSendingNewMessages(value: boolean): void {
        this.sendingNewMessages = value;
    }

    public storePushNotificationSubscription(pushSubscription: PushSubscription): Observable<any> {
        const credentials = this.getStoredCredentials();
        if (credentials) {
            return this.http.request({
                url: `${this.chatEndpoint}${credentials.token}/store_push_notification_subscription?bot_name=${this.botName}&bot_environment=${this.botEnvironment}&client_id=${credentials.client_id}`,
                method: 'POST',
                body: JSON.stringify(pushSubscription),
                headers: {
                    'Content-type': 'application/json',
                },
            });
        }
        return throwError('Missing credentials');
    }

    public get(referral: IReferral): Observable<IConversationBlock> {
        return this.startPolling(referral);
    }

    public send(message: IUserMessage): void {
        let retryCount = 0;
        this.getCredentials()
            .pipe(
                mergeMap((credentials: Credentials) => {
                    if (!this.credentials) {
                        return throwError('Credentials must not be null at this point');
                    }
                    const url = `${this.chatEndpoint}${credentials.token}?client_id=${
                        credentials.client_id
                    }&_${new Date().getTime()}&bot_name=${this.botName}&bot_environment=${this.botEnvironment}`;
                    return this.http.request({
                        url,
                        method: 'POST',
                        body: JSON.stringify({
                            ...message,
                            ...{
                                extra: {
                                    client_id: credentials.client_id,
                                    url: window.location.href,
                                },
                            },
                        }),
                        headers: {
                            'Content-type': 'application/json',
                        },
                    });
                }),
                retryWhen((errors) =>
                    errors.pipe(
                        mergeMap((error) => {
                            retryCount += 1;
                            if (error.status === 401 && retryCount <= 3) {
                                // retry on 401
                                this.cleanCredentials();
                                return of(null);
                            }
                            // don't retry
                            return throwError(error);
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    public storeClientIdIfNotExists(clientId: string | null): void {
        if (clientId !== null) {
            localStorage.setItem(this.clientIdKey, clientId);
            this.credentials = { token: null, client_id: clientId };
        } else {
            this.cleanCredentials();
        }
    }

    private sendPing(referral: IReferral, shouldStartConversation: boolean): void {
        const referralShouldBeSent = Object.keys(referral).length !== 0 && this.referralEntityName;
        if (!referralShouldBeSent && !shouldStartConversation) {
            return;
        }
        const message: IUserMessage = {
            content: {
                text: '',
                action: '',
                entities: referralShouldBeSent ? { [this.referralEntityName]: referral } : {},
                intent: '',
                goto: shouldStartConversation ? 'messenger_entrypoint' : '',
            },
            content_type: 'postback',
        };
        this.sendingNewMessages = true;
        this.send(message);
    }

    private connect(clientId: string | null): Observable<Credentials> {
        let url = `${this.chatEndpoint}authenticate?bot_name=${this.botName}&bot_environment=${this.botEnvironment}`;
        if (clientId) {
            url += `&client_id=${clientId}`;
        }
        return this.http
            .request({
                url,
            })
            .pipe(
                tap((obj: Credentials) => {
                    this.storeCredentials(obj);
                }),
            );
    }

    private getCredentials(): Observable<Credentials> {
        return of(this.credentials).pipe(
            mergeMap((cred: Credentials | null) => {
                if (cred && cred.token) {
                    return of(cred);
                }
                return this.connect(cred ? cred.client_id : null);
            }),
        );
    }

    private poll(referral: IReferral): Observable<IConversationBlock> {
        return this.getCredentials().pipe(
            tap(() => {
                if (this.lastTimestamp) {
                    this.sendingNewMessages = true;
                }
            }),
            mergeMap((cred: Credentials) => {
                const firstPart = `${cred.token}?client_id=${cred.client_id}`;
                const secondPart = this.lastTimestamp ? `&last_message_date=${this.lastTimestamp}` : '';
                const thirdPart = `&_${new Date().getTime()}&bot_name=${this.botName}&bot_environment=${
                    this.botEnvironment
                }`;
                return this.http.request({
                    url: `${this.chatEndpoint}${firstPart}${secondPart}${thirdPart}`,
                    method: 'GET',
                });
            }),
            tap((jsonResponse: IConversationBlock[]) => {
                if (!this.sendingNewMessages && jsonResponse) {
                    this.sendPing(referral, jsonResponse.length === 0);
                }
            }),
            // emit for each conversation block
            mergeMap((blocks: IConversationBlock[]) => from(blocks)),
            tap(
                (cv: IConversationBlock) =>
                    (this.lastTimestamp = Math.max(cv.hook_reception_date || 0, this.lastTimestamp || 0)),
            ),
            tap((cv: IConversationBlock) => {
                store.commit('setConversationContext', cv.bot_message.context);
            }),
            filter(
                (cv: IConversationBlock) =>
                    !(cv.user_message && cv.user_message.extra && cv.user_message.extra.is_api_message),
            ),
            catchError((error) => {
                if (error.status === 401 || error.status === 403) {
                    this.cleanCredentials();
                }
                // retry on HttpErrorResponse
                return EMPTY;
            }),
        );
    }

    private startPolling(referral: IReferral): Observable<IConversationBlock> {
        return interval(this.pollingIntervalMs).pipe(
            exhaustMap(() => this.poll(referral)), // if a request is pending, we wait for it to complete
            catchError(() => EMPTY),
        );
    }

    private getStoredCredentials(): Credentials | null {
        const token = localStorage.getItem(this.tokenKey);
        const clientId = localStorage.getItem(this.clientIdKey);
        return clientId ? { token, client_id: clientId } : null;
    }

    private storeCredentials(cred: Credentials): void {
        if (this.credentials != null) {
            if (this.credentials.token === null) {
                localStorage.setItem(this.tokenKey, cred.token as string);
                this.credentials.token = cred.token;
            }
            return;
        }
        this.credentials = cred;
        localStorage.setItem(this.tokenKey, cred.token as string);
        localStorage.setItem(this.clientIdKey, cred.client_id);
    }

    private cleanCredentials(): void {
        this.credentials = null;
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.clientIdKey);
    }
}
