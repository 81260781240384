import BotCarouselMessageSlide from '@/components/messages/bot-messages/carousel/BotCarouselMessageSlide/BotCarouselMessageSlide.vue';
import MessageMixin from '@/mixins/message-mixin';
import { ICarouselBotMessageContent } from '@/models/bot-models';
import { VueAgile } from 'vue-agile';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    components: {
        BotCarouselMessageSlide,
        carousel: VueAgile,
    },
})
export default class BotCarouselMessage extends MessageMixin {
    @Prop({ default: () => ({}) }) public content!: ICarouselBotMessageContent;
    public carouselOptions: { [key: string]: any } = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        autoplay: false,
        centerMode: true,
    };
}
