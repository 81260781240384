import { AppConfig } from '@/app.config';
import { IReferral } from '@/models/bot-models';
import { IConversationParameters } from '@/models/chat-parameters';
import * as _ from 'lodash';
import { inject, injectable } from 'vue-typescript-inject';

@injectable()
export default class ChatParametersProvider {
    private readonly storagePrefix: string;

    private readonly referralKeys: string[] = ['ref', 'referral', 'source', 'type', 'referer_uri', 'ad_id'];

    private get clientIdKey(): string {
        return `${this.storagePrefix}-botConversationId`;
    }

    constructor(@inject() appConfig: AppConfig) {
        this.storagePrefix = appConfig.storagePrefix;
    }

    public getConversationParameters(): IConversationParameters {
        // @ts-ignore
        const divAttributes: NamedNodeMap = document.getElementById('randy-webchat').attributes;
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);

        const clientId: string | null = this.getClientId(divAttributes, urlParams);

        const referral: IReferral = {};
        this.getReferralElementsFromDiv(divAttributes, referral);
        this.getReferralElementsFromUrl(urlParams, referral);

        return {
            clientId,
            referral,
        };
    }

    public getGreetingMessageContent(): string | null {
        // @ts-ignore
        const divAttributes: NamedNodeMap = document.getElementById('randy-webchat').attributes;
        const messageContentFromDiv = divAttributes.getNamedItem('greeting_message_content');
        if (messageContentFromDiv) {
            return messageContentFromDiv.value;
        }
        return null;
    }

    public getGreetingMessageDelay(): number {
        // @ts-ignore
        const divAttributes: NamedNodeMap = document.getElementById('randy-webchat').attributes;
        const messageDelayFromDiv = divAttributes.getNamedItem('greeting_message_delay_ms');
        if (messageDelayFromDiv) {
            return parseInt(messageDelayFromDiv.value, 10);
        }
        return 1500;
    }

    public getStartButtonDisplayDelay(): number {
        // @ts-ignore
        const divAttributes: NamedNodeMap = document.getElementById('randy-webchat').attributes;
        const startButtonDelayFromDiv = divAttributes.getNamedItem('start_button_delay_ms');
        if (startButtonDelayFromDiv) {
            return parseInt(startButtonDelayFromDiv.value, 10);
        }
        return 0;
    }

    private getClientId(divAttributes: NamedNodeMap, urlParams: URLSearchParams): string | null {
        const clientIdFromDiv = divAttributes.getNamedItem('client_id');
        const clientIdFromUrl = urlParams.get('randy_client_id');
        const clientIdFromStorage = this.getFromLocalStorage(this.clientIdKey);
        if (clientIdFromDiv !== null) {
            return clientIdFromDiv.value;
        }
        if (clientIdFromUrl) {
            return clientIdFromUrl;
        }
        if (clientIdFromStorage) {
            return clientIdFromStorage;
        }
        return null;
    }

    private getFromLocalStorage(key: string): string | null {
        return localStorage.getItem(key);
    }

    private getReferralElementsFromDiv(divAttributes: NamedNodeMap, referral: IReferral) {
        if (this.referralKeys.some((key) => divAttributes.getNamedItem(key) !== null)) {
            this.referralKeys.forEach((key) => {
                const keyAttrFromDiv = divAttributes.getNamedItem(key);
                const referralKey = key === 'referral' ? 'ref' : key;
                if (keyAttrFromDiv !== null && !_.has(referral, referralKey)) {
                    _.set(referral, referralKey, keyAttrFromDiv.value);
                }
            });
        }
    }

    private getReferralElementsFromUrl(urlParams: URLSearchParams, referral: IReferral) {
        if (this.referralKeys.some((key: string) => urlParams.get(`randy_${key}`))) {
            this.referralKeys.forEach((key: string) => {
                const keyAttrFromUrl: string | null = urlParams.get(`randy_${key}`);
                const referralKey = key === 'referral' ? 'ref' : key;
                if (keyAttrFromUrl && !_.has(referral, referralKey)) {
                    _.set(referral, referralKey, keyAttrFromUrl);
                }
            });
        }
    }
}
