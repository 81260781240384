import { injectable } from 'vue-typescript-inject';

@injectable()
export class AppConfig {
    public readonly botName: string;
    public readonly botEnvironment: string;
    public readonly chatEndpoint: string;
    public readonly pollingIntervalMs: number;
    public readonly storagePrefix: string;
    public readonly pushNotificationPublicKey: string;
    public readonly referralEntityName: string;

    constructor() {
        // @ts-ignore
        const appConstants = window.WEBCHAT_CONFIG;
        this.botName = appConstants?.botName ?? process.env.VUE_APP_BOT_NAME;
        this.botEnvironment = appConstants?.botEnvironment ?? process.env.VUE_APP_BOT_ENV;
        this.chatEndpoint = appConstants?.chatEndpoint ?? process.env.VUE_APP_CHAT_ENDPOINT;
        this.pollingIntervalMs = appConstants?.pollingIntervalMs ?? process.env.VUE_APP_POLLING_INTERVAL_MS;
        this.storagePrefix =
            (appConstants?.storagePrefix ?? process.env.VUE_APP_LOCAL_STORAGE_PREFIX) +
            this.botName +
            this.botEnvironment;
        this.pushNotificationPublicKey =
            appConstants?.pushNotificationPublicKey ?? process.env.VUE_APP_PUSH_NOTIFICATION_PUBLIC_KEY;
        this.referralEntityName = appConstants?.referralEntityName ?? process.env.VUE_APP_REFERRAL_ENTITY_NAME;
    }
}
